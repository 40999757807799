.period-name {
    font-size: 2em;
}

.period-summary-item {
    text-align: center;
}

.period-head {
    background-color: #888888;
}
